import React, { useState } from "react";

const data = [
  {
    name: "Admin Dashboard",
    img: "./projects/adminDashboard.png",
    colour: "#24303f",
    desc: "A comprehensive admin dashboard for managing users, analytics, and settings efficiently."
  },
  {
    name: "Boutique",
    img: "./projects/eboutiqe.png",
    colour: "#ea580c",
    desc: "An elegant online boutique showcasing a variety of fashion products with a seamless shopping experience."
  },
  {
    name: "Cafe Ordering System",
    img: "./projects/cafeMG.png",
    colour: "#7b7fb6",
    desc: "A user-friendly cafe ordering system to streamline customer orders and improve service efficiency."
  },
  {
    name: "Fmovies-Clone",
    img: "./projects/fmovies.png",
    colour: "#00acc1",
    desc: "A sleek clone of the Fmovies platform for browsing and streaming movies with ease."
  },
  {
    name: "Dozy Kooki",
    img: "./projects/kooki.png",
    colour: "#00acc1",
    desc: "A charming cafe website showcasing a diverse menu, cafe details, and a welcoming ambiance for customers."
  }
];


export default function Projects() {
  const [hoverColour, setHoverColour] = useState(null);

  return (
    <div className="flex justify-center bg-primary text-white w-full py-10">
      <div className=" w-mobile md:w-desktop  ">
        <div className="">
          <div className=" flex justify-center items-center w-full">
            <div className=" text-[1.8rem] md:text-[3rem] pb-5 font-bold ">
              My Works
              <span className="block bg-white h-1 w-full mt-[1px]"></span>
            </div>
          </div>
          <div className=" mt-5">
            <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 ">
              {data.map((item, index) => (
                <div
                  className="group relative border rounded-2xl w-full  hover:grayscale-0 ease-out duration-200 transform hover:scale-[1.02]"
                  onMouseEnter={() => {
                    setHoverColour(index);
                  }}
                  onMouseLeave={() => {
                    setHoverColour(null);
                  }}
                  style={{
                    borderColor:
                      hoverColour === index ? item.colour : "transparent", // Change border color when hovered
                  }}
                >
                  <div className="h-full w-full">
                    <img
                      src={item.img}
                      alt={item.name}
                      className="object-cover bg-transparent rounded-2xl w-full"
                    />
                  </div>
                  <div className="absolute grayscale bottom-0 left-0 w-full h-0 bg-gradient-to-b from-transparent to-black rounded-b-2xl overflow-hidden p-6 text-left transition-all duration-500 group-hover:h-full">
                    <div className="text-white  transform translate-y-full group-hover:translate-y-0 transition-transform duration-500 ease-out flex flex-col justify-end items-start">
                      <div className="w-full h-full flex flex-end flex-col pt-[6rem]  ">
                        <h3 className="text-[1.5rem] font-bold ">
                          {item.name}
                        </h3>
                        <p className="pb-1">{item.desc}</p>
                        <p className=" ">
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
