import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";


const Header = () => {
  const [bgColor, setBgColor] = useState("black");
  const [textColor, setTextColor] = useState("black");
  const [hoverColor, setHoverColor] = useState("white");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerHeight * 0.7;

      if (scrollPosition > threshold) {
        setTextColor("white");
        setBgColor("white");
        setHoverColor("black");
      } else {
        setTextColor("black");
        setBgColor("black");
        setHoverColor("white");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Debugging state changes
  useEffect(() => {
    console.log("Updated State:");
    console.log("Text Color:", textColor);
    console.log("Background Color:", bgColor);
    console.log("Hover Color:", hoverColor);
  }, [bgColor, textColor, hoverColor]); // Listen for changes in these states

  const menuItems = [
    { icon: <FaFacebookF />, id: "facebook" },
    { icon: <FaLinkedinIn />, id: "linkedin" },
    { icon: <FaXTwitter />, id: "twitter" },
  ];

  return (
    <motion.div
      initial={{ y: -200 }}
      animate={{ y: 0 }}
      exit={{ y: -200 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      className={`header z-[999999] text-${textColor} sticky top-0 bg-opacity-50 backdrop-filter backdrop-blur-sm flex justify-center items-center pt-3`}
    >
      <AnimatePresence>
        <div className="w-mobile md:w-desktop">
          <header className="flex justify-between items-center p-2">
            <h1 className="text-4xl font-extrabold">삶</h1>

            <ul className="flex gap-2 md:gap-4 items-center">
              {menuItems.map(({ icon, id }) => (
                <li
                  key={id}
                  className={`group border p-1 rounded-md h-8 md:h-10 aspect-square flex items-center justify-center ease-out duration-200 transform hover:scale-125 hover:bg-${textColor} hover:text-${hoverColor}`}
                >
                  {React.cloneElement(icon, {
                    className: `text-[1.2rem] md:text-[1.5rem] group-hover:text-${hoverColor}`,
                  })}
                </li>
              ))}
              <li
                className={`group border p-1 rounded-md h-8 md:h-10 aspect-square flex flex-col justify-center items-center gap ease-out duration-200 transform hover:scale-125 hover:bg-${textColor}`}
              >
                <div
                  className={`relative h-full w-full bg-gradient-to-r rounded-lg flex justify-center items-center group-hover:bg-${textColor}`}
                >
                  <div
                    className={`h-4 w-4 border-2 border-${textColor} group-hover:border-${hoverColor} hover:bg-${textColor} rounded-full`}
                  ></div>
                  <div
                    className={`absolute top-0 right-0 h-1 w-1 bg-${textColor} group-hover:bg-${hoverColor} rounded-full`}
                  ></div>
                </div>
              </li>
            </ul>

            <button
              className={`border-[1px] px-3 py-1 rounded-md text-xl font-bold text-[0.8rem] md:text-[1.5rem] hover:bg-${bgColor} hover:text-${hoverColor} ease-out duration-200 transform hover:scale-125`}
            >
              Resume
            </button>
          </header>
        </div>
      </AnimatePresence>
    </motion.div>
  );
};

export default Header;